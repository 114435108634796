<template>
    <div class="AdjCal full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height">
            <div class="content-area pa-8 flex-grow-1 bg-white">
                <v-row>
                    <v-col cols="4" class="d-flex">
                        <router-link to="/adjustment" v-ripple class="content-btn adjustment">
                            {{ $t('adjCal.adjustment') }}
                        </router-link>
                    </v-col>
                </v-row>
                <v-row class="mt-0">
                    <v-col cols="4" class="d-flex">
                        <router-link to="/calibration" v-ripple class="content-btn calibration">
                            {{ $t('adjCal.calibration') }}
                        </router-link>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import FooterButton from '@/components/FooterButton.vue'

export default {
    name: 'AdjCal',
    components: {
        FooterButton,
    },
    props: {
    },
    data () {
        return{
        }
    },
    computed: {
    },
    methods:{
    }
}
</script>
<style scoped lang="scss">
.v-application .content-btn {
    justify-content: left;

    &::before {
        content: "";
        width: 30px;
        height: 20px;
        margin-right: 10px;
        margin-bottom: 3px;
        background-size: contain;
        background-position: center;
    }
    &.adjustment {
        &::before {
            background-image: url("../assets/adjust_weiss.svg");
            height: 22px;
        }
    }
    &.calibration {
        &::before {
            background-image: url("../assets/dot-circle-regular_weiss.svg");
            height: 22px;
        }
    }
}
</style>